













import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class LinkPreview extends Vue {
  @Prop() domain?: string;

  @Prop() url!: string;

  @Prop() meta!: OutlineMeta;

  get urlPrefference(): string {
    if (this.meta && this.meta.og && this.meta.og["og:image"]) {
      return this.meta.og["og:image"];
    }
    return `https://www.google.com/s2/favicons?sz=128&domain=${this.domain}`;
  }
}
